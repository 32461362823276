.hardship-hero {
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgb(0, 127, 173) 0%, rgba(0, 90, 163, 1) 100%);
  height: auto;
  padding: 40px 0 60px;

  h1 {
    width: 900px;
    margin: auto;
    padding-top: 3rem;
  }

  p {
    width: 800px;
    margin: auto;
  }

  .img-container {
    width: 500px;
    margin: 20px auto;
  }

  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 2.75rem;
      padding-top: 40px;
      width: 100%;
    }

    p {
      width: 100%;
    }

    .img-container {
      width: 100%;
    }
  }

  .pipe {
    height: auto;
    width: 1px;
    background-color: white;
    margin: 0 0.5rem;
  }

  .call-to-action {
    background-color: white;
    color: $primary-blue;

    &:hover {
      background-color: #EDEBEB;
      color: $primary-blue;
    }
  }
}
