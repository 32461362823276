.application-summary-read-only-components {
  .application-label {
    font-size: 0.9rem;
    color: $grey;
  }

  .information-bg {
    border: 1px solid $grey;
    padding-left: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.2rem;
  }

  & input {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
  }
}
