.customer-summary {
  color: white;

  .debt-score-calendar,
  .credit-score-calendar {
    width: 500px;
    @media only screen and (max-width: 640px) {
      width: 320px;
      left: -0.2rem;
    }
    margin: 2rem auto 0;

    &img {
      width: 100%;
    }
  }

  .cashflow-debts-expenses {
    text-align: center;
    padding-top: 2rem;

    .value {
      font-size: 3rem;
      font-family: 'Rift';
      margin-top: -0.7rem;

      &.financial-summary-value {
        font-size: 24px
      }

      &.color-red {
        color: $vibrant-pink;
      }

      &.color-green {
        color: $green;
      }

      .arrows {
        background-repeat: no-repeat;
        content: "";
        position: relative;
        height: 21px;
        width: 17px;
        float: left;
        top: 1rem;
        left: -0.5rem;

        &.red-down-arrow {
          background-image: url('../../../assets/icons/down-arrow_icon.svg');
        }

        &.green-up-arrow {
          background-image: url('../../../assets/icons/up-arrow_icon.svg');
        }
      }
    }

    .progress {
      text-align: left;
      width: 500px;
      margin: auto;
      @media only screen and (max-width: 640px) {
        width: 100%;
      }
      .progress-value {
        font-size: 1.125rem;
        font-weight: bold;
        &.color-green {
          color: $green;
        }

        &.color-red {
          color: $vibrant-pink;
        }
      }

      .arrows {
        background-repeat: no-repeat;
        content: "";
        position: relative;
        height: 11px;
        width: 9px;
        float: left;
        top: 0.3rem;
        left: -3px;

        &.up-arrow {
          background-image: url('../../../assets/icons/up-arrow-sm_icon.svg');
        }

        &.down-arrow {
          background-image: url('../../../assets/icons/down-arrow-sm_icon.svg');
        }
      }

      .progress-label {
        font-size: 0.8rem;
        margin-top: -0.2rem;
        margin-bottom: 0.5rem;
        color: $light-grey-color;
      }

      @keyframes progress-bar-animation {
        0% {
          opacity: 0;
          left: 0;
          transform: scale(1, .5); /* Equal to scaleX(2) scaleY(0.5) */
          transform-origin: left;
          filter: blur(1.5rem);
        }

        50% {
          opacity: .5;
          filter: blur(4px);
        }

        100% {
          opacity: 1;
          left: 100%;
          filter: blur(0);
        }
      }

      .green-progress-bg {
        background-color: #193948;
        height: 0.5rem;
      }

      .green-progress {
        animation: progress-bar-animation 1s linear forwards;
        background-color: $green;
        height: 0.5rem;
        max-width: 100%;
      }

      .red-progress-bg {
        background-color: #282743;
        height: 0.5rem;
      }

      .red-progress {
        animation: progress-bar-animation 1s linear forwards;
        background-color: $vibrant-pink;
        height: 0.5rem;
        max-width: 100%;
      }
    }
  }
}
