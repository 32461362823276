.color-icon-detail {
    border-radius: 5rem;
    &.bg-lightblue {
        background-color: $light-blue;
    }
    &.bg-primaryblue {
        background-color: $primary-blue;
    }
    &.bg-vibrantpink {
        background-color: $vibrant-pink;
    }
    &.bg-darkorange {
        background-color: $dark-orange;
    }
    &.bg-green {
        background-color: $green;
    }
    &.bg-darkgrey {
        background-color: #5D7486;
    }
    &.bg-purple {
        background-color: $purple;
    }
    &.bg-grey {
        background-color: $grey;
    }
    &.bg-mediumgrey {
        background-color: #6B6B6B;
    }
    &.bg-darkgreen {
        background-color: $dark-green;
    }
}