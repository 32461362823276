.customer-alerts {
  .customer-alert-tile {
    .alert-title {
      font-size: 1rem;
      font-weight: bold;
    }

    .alert-subtitle {
      font-size: 0.75rem;
    }
  }
}
