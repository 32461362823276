.bank-connection {
  &.call-to-action {
    padding: 1rem 4rem;
    min-width: 22rem;
  }

  &.plaid-call-to-action {
    padding: 0!important;
    background-color: $primary-blue;
    width: 22rem;
    color: white;
    margin: 40px auto 0;
  }

  &.ko-call-to-action {
    color: $primary-blue;
    margin-bottom: 1.5rem;
    width: 22rem;
    padding: 0;
  }

  .border-none {
    border: none!important;
    width: 100%;
    background: transparent!important;
    padding: 1rem 0!important;
  }

  .bullet-points {
    h3 {
      font-weight: bold;
      font-size: 0.9rem;
      text-transform: none;
      text-align: left;
    }

    p {
      font-size: 0.75rem;
      color: #A2A2A2;
      text-align: left;
    }

    ol {
      margin: 0 0 1em 0;
      padding: 0;
      counter-reset: li;
      list-style: none;
      li {
        display: block;
        overflow: hidden;
        position: relative;
        padding-left: 50px;
        min-height: 35px;
        margin: 10px 0px;
        padding-top: 4px;
        text-align: left;
        &:before {
          position: absolute;
          top: 0px;
          left: 0px;
          border: 1px solid $primary-blue;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
          color: $primary-blue;
          content: counter(li);
          counter-increment: li;
        }
      }
    }

    img {
      padding-right: 1rem;
    }
  }

  .skip-bank-connection {
    a {
      color: $primary-blue;
    }

    .note-details {
      font-size: 0.8rem;
      color: #A2A2A2;
    }
  }

  .shield-stroke {
    stroke: $light-blue;
  }
}
