@import './DebtAnalysis/debt-analysis';
@import './DebtRelief/debt-relief';
@import './Referral/referral';
@import './RenterAdvantage/renter-advantage';
@import './common/VideoModal/video-modal';
@import './common/HowItWorksModal/how-it-works-modal';

// Custom font //
@font-face {
  font-family: "Montserrat-reg";
  src: url("https://assets.creditgenie.com/global/fonts/montserrat/montserrat-regular.woff") format("woff");
}

@font-face {
  font-family: "Montserrat-black";
  src: url("https://assets.creditgenie.com/global/fonts/montserrat/montserrat-black.woff") format("woff");
}

@font-face {
  font-family: "Montserrat-light";
  src: url("https://assets.creditgenie.com/global/fonts/montserrat/montserrat-light.woff") format("woff");
}
