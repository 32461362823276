.borrower-queue-components {
  .dynamic-info {
    position: relative;
    top: -3.4rem;
    @media only screen and (max-width: 640px) {
      top: 0;
    }
  }

  .results {
    @media only screen and (max-width: 640px) {
      bottom: 25px;
    }
  }

  .customer-rep-info {
    width: 30rem;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 20px;
    }

    .profile-photo {
      border-radius: 50%;
      max-width: 10rem;
      &-box {
        position: relative;
      }
    }

    .heading {
      &-contacted {
        color: $orange;
      }
      &-assigned {
        color: $primary-blue;
      }
    }

    h1 {
      font-size: 4rem;
    }

    h4 {
      text-transform: lowercase;
      font-size: 1.4rem;
      font-weight: 600;
    }

    a {
      color: $primary-blue;
    }
  }

  .dynamic-info {
    width: 70%;
    margin-left: 2rem;
    margin-top: .9rem;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      margin-left: 0;
    }
  }

  .box {
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 1rem;
    position: relative;
    top: -160px;
    min-height: 200px;
    @media only screen and (max-width: 1024px) {
      top: -115px;
    }

  .detail-box {
    height: 8rem;
  }

    @media only screen and (max-width: 736px) {
      top: -90px;
    }
  }

  .vertical-divider {
    border-left: 1px solid #E5E5E5;
    height: 6rem;
    margin-top: 1rem;
  }
}
