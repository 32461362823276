.sub-hero {
  height: 30rem;
  @media only screen and (max-width: 640px) {
    height: 20rem;
  }

  h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    line-height: 1.5rem;
  }
}