.how-can-we-help {
  background-color: #150C39;
  color: white;

  .benefits {
    text-align: center;
    width: 100%;
    padding-bottom: 4rem;

    .benefit-img {
      margin: 0.5rem auto 0 auto;
      padding-top: 0.2rem;

      &.up-arrow {
        width: 59px;
        height: 40px;
      }

      &.housing {
        width: 55px;
        height: 75px;
      }

      &.credit-card {
        width: 68px;
        height: 46px;
      }

      &.counselor {
        width: 60px;
        height: 58px;
      }
    }

    .arrow-benefit-details {
      margin: auto;
      padding-top: 2rem;
    }
  }
}
