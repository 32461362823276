.customer-settings {
  background-color: $dark-blue;

  .customer-settings-container {
    background-color: white;
    border-radius: 5rem solid white;
    width: 385px;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    .sub-info {
      color: $grey;
    }
  }

  .ctas-KO {
    border: 1px solid $primary-blue;
    background-color: transparent;
    padding: 1rem;
    width: 164px;
  }
}
