ul.reset-password-validators {
  padding: inherit;
  
  li {
    animation: fadeIn .5s;
    list-style-type: disc;
    grid-template-columns: auto auto;
    font-size: small;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  li.validated {
    list-style-image: url('../../../assets/icons/pw-validation-checkmark_icon.svg');

    span {
      color: $green;
    }
  }

}