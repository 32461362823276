.financial-summary-calendar {
  position: relative;
  padding-bottom: 2rem;
  left: 35px;

  svg {
    width: 100%;
    height: 150px;
    overflow: visible;
    @media only screen and (max-width: 640px) {
      width: 80%;
      position: relative;
      left: 5%;
    }
  }

  .path {
    fill: none;
    stroke: none;
    stroke-width: 1;

    .path-container {
      text {
        &.x-axis {
          font-size: 12px;
          fill: $light-grey-color;
          text-anchor: left;
        }

        &.y-axis {
          font-size: 12px;
          fill: $light-grey-color;
          text-anchor: end;
        }
      }

      circle {
        &.green {
          fill: $green;
        }

        &.red {
          fill: $vibrant-pink;
        }

        &.white {
          fill: white;
        }
      }

      line {
        &.green {
          stroke: $green;
        }

        &.red {
          stroke: $vibrant-pink;
        }

        &.white {
          stroke: white;
        }
      }
    }
  }

  @keyframes dash {
    to {
      stroke-dashoffset: -500;
    }
  }

  @keyframes opacity-transition {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
