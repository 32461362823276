.filter-container {
  @keyframes filter-animation {
    0% {
      opacity: 0;
      filter: blur(1.5rem);
    }

    50% {
      opacity: .5;
      filter: blur(4px);
    }

    100% {
      opacity: 1;
      filter: blur(0);
    }
  }

  animation: filter-animation .3s linear forwards;
  position: absolute;
  margin: 0 auto;
  z-index: 3;
  max-width: 530px;
  height: auto;
  left: 50%;
  transform: translate(-50%, 0%);

  @media screen and (max-width: 500px) {
    top: 35px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 31px;
    left: 40px;

    @media screen and (max-width: 1024px) {
      left: 36px;
    }

    @media screen and (max-width: 768px) {
      left: 30px;
    }

    @media screen and (max-width: 500px) {
      left: 30px;
      top: 30px;
    }

    width: 14px;
    height: 14px;
    background-color: white;
    transform: rotate(45deg);
    display: block;
    margin: -7px auto 0;
    z-index: -1;
  }

  .container {
    @media screen and (min-width: 300px) and (max-width: 500px) {
      font-size: 0.9rem;
    }
  }

  .call-to-action {
    padding: 1rem;
    width: 50%;
  }

  .cancel-value {
    padding: 1rem;
    border: 1px solid $primary-blue;
    color: $primary-blue;
    text-align: center;
  }
}

.filter-header {
  display: flex;
  justify-content: space-between;

  >h1 {
    padding-top: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1rem;
  }
}

.filter-title {
  display: flex;
  justify-content: space-between;

  >h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .failure.filter-failure {
    padding: .5rem 0 0 0;
    margin: .5rem;
  }
}

.filter-contents {
  position: relative;
  background-color: white;
  width: 100%;
  top: 31px;
  padding: 1rem;
  border-radius: .5rem;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.2);
  z-index: -1;
}
