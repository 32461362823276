.customer-debt-analysis {
  .debt-summary {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 6rem auto 0 auto;
    @media only screen and (max-width: 640px) {
      margin: 5rem auto 0 auto;
    }
  }

  .cashflow-debts-expenses {
    padding-top: 0rem;
  }

  .label-text {
    font-size: 0.9rem;
    color: $light-grey-color;

    span {
      &.color-green {
        color: $green;
      }

      &.color-red {
        color: $vibrant-pink;
      }
    }
  }

  .sub-values {
    width: 31rem;
    margin: auto;
    padding-top: 2rem;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    .value {
      font-size: 2rem;
    }

    .est-total-interest-charges {
      &.arrows {
        left: 4.5rem;
        @media only screen and (max-width: 475px) {
          left: 2.5rem;
        }
      }
    }

    .avg-interest-rate {
      &.arrows {
        left: 5.5rem;
        @media only screen and (max-width: 475px) {
          left: 3.5rem;
        }
      }
    }
  }

  .color-black {
    color: black;
  }

  .color-red {
    color: $vibrant-pink;
  }
}
