.alert {
  .checkmark {
    margin: auto;
    margin-right: 115px;
  }

  .date {
    color: $grey;
    font-size: 0.8rem;
    font-weight: normal;
  }

  .mark-as-seen-button {
    background-color: #49a4d5;
    border-radius: 24px;
    margin-right: 40px;
    margin-top: 0;
    min-width: 160px;
    outline: none;
    padding: 0.75rem 2.5rem;
    width: auto;

    &:hover {
      background-color: $secondary-blue;
    }
  }

  .mark-all-alerts {
    border-radius: 24px;
    margin-right: 40px;
    margin-top: 0;
    width: auto;
    min-width: 160px;
    max-width: 22rem;
    outline: none;
    padding: 0.75rem 1.5rem;
  }

  .seen {
    color: $green;
    margin: auto;
    margin-right: 115px;
    width: 35px;
  }
}

.alert-badge {
    background-color: $red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
