.credit-score {
    height: 170px;
  
    .svg {
      width: 313px;
      height: 184px;
      overflow: visible;
  
      g {
        &.g-container {
          stroke: none;
          stroke-width: 1;
          fill: none;
          fill-rule: evenodd;
        }
      }
  
      .credit-graphic-container {
        stroke-linecap: round;
        path {
          &.red {
            stroke: $vibrant-pink;
            animation: dash .1s linear;
          }
  
          &.orange {
            stroke: $orange;
          }
  
          &.light-blue {
            stroke: $primary-blue;
          }
  
          &.dark-green {
            stroke: $dark-green;
          }
  
          &.green {
            stroke: $green;
          }
  
          &.black {
            stroke: black;
          }
        }
      }
  
      circle {
        stroke: $dark-blue;
        fill: $dark-blue;
      }
  
      text {
        &.credit-score-value {
          font-family: 'Rift';
          fill: white;
          letter-spacing: 0.4px;
          font-size:  48px;
        }
  
        &.credit-score-label {
          fill: white;
        }
  
        &.credit-score-date {
          font-size: 0.8rem;
          fill: $light-grey;
          text-align: center;
        }
  
        &.range {
          fill: white;
        }
      }
    }
  }
