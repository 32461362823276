.debt-summary {
  margin: 0 auto;

  &.dynamic-info {
    width: 100%;
  }

  .search-bar {
    top: -75px;
    width: 100%;
    left: 0;
  }
}
