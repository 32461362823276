$nfcc-green: #9AC03F;
.progress-bar-contain {
  color: $nfcc-green;
  .progress-bar {
    margin: auto;
    li {
      list-style-type: none;
      float: left;
      position: relative;
      margin: 0 40px;
      @media only screen and (max-width: 375px) {
        margin: 0 25px;
      }

      &::after {
        content: '';
        position: absolute;
        width: 60px;
        height: 1px;
        background-color: $grey;
        top: 16px;
        left: -70px;
        z-index: 1;
        @media only screen and (max-width: 375px) {
          width: 30px;
          left: -40px;
        }
      }

      &:first-child:after {
        content: none;
      }
    }
  }

  .personal-information {
    background-image: url(../../../../assets/icons/progress-bar/personal-information_grey_icon.svg);
    background-repeat: no-repeat;
    width: 41px;
    height: 41px;
  }

  .visited-personal-information {
    background-image: url(../../../../assets/icons/progress-bar/personal-information_visited_icon.svg);
  }

  .verify-income-and-expenses {
    background-image: url(../../../../assets/icons/progress-bar/verify-income-and-expenses_grey_icon.svg);
    background-repeat: no-repeat;
    width: 41px;
    height: 41px;
  }

  .visited-verify-income-and-expenses {
    background-image: url(../../../../assets/icons/progress-bar/verify-income-and-expenses_visited_icon.svg);
  }

  .connect-bank-account {
    background-image: url(../../../../assets/icons/progress-bar/connect-bank-accounts_grey_icon.svg);
    background-repeat: no-repeat;
    width: 41px;
    height: 41px;
  }

  .visited-connect-bank-account {
    background-image: url(../../../../assets/icons/progress-bar/connect-bank-accounts_visited_icon.svg);
  }

  .progress-title {
    color: $light-blue;
  }
}

