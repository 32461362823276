$nfcc-green: #9AC03F;

.income-tile {
  background-color: white;
  border: 1px solid #979797;
  border-radius: .06rem;
  padding: 10px;
  max-width: 550px;
  margin: 0 auto;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  &.active {
    border-color: $primary-blue;
    background-color: $light-bg-blue;
  }

  .transaction-type {
    font-weight: bold;
  }

  .transaction-value {
    font-family: 'Rift';
    font-size: 2.25rem;
    color: $green;
  }

  .checkbox {
    position: relative;
    top: -40px;
    left: 4px;
  }

  .date {
    font-size: .9rem;
    position: relative;
    top: 0.1rem;
  }

  a {
    padding: 40px;
  }

  .width-adjustments {
    width: 75%;
  }
}

.select-all-container {
  max-width: 550px;
  margin: auto;

  .select-all {
    max-width: 100px;
    position: relative;
    left: -50px;

    .checkmark {
      left: 73px;
      top: -25px;
    }

    .container {
      padding-bottom: 5px;
    }
  }
}

.verify-income {
  .call-to-action {
    background-color: $primary-blue;
    padding: 1rem 4rem;
  }
}

.verified-as-income-text {
  text-align: right;
  float: right;
  position: relative;
  top: -2.1rem;
  color: $nfcc-green;
}
