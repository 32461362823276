.monthly-savings-amount {
  color: $green;
  font-size: 3.25rem;
  font-family: 'Rift';
  text-align: center;
  z-index: 10;

  &-subtext {
      color: $green;
  }
}

.credit-counseling-section {
  background-color: $light-grey;
  color: $primary-blue;
}
