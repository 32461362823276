.proposed-payment-tile {
  width: 25rem;
  margin-top: 0.7rem;
  background-color: white;
  border: 1px solid $grey;
  @media only screen and (max-width: 640px) {
    width: 21rem;
  }

  @media only screen and (max-width: 375px) {
    width: 18rem;
  }

  .lender-logo {
    background-color: white;
    padding: 0.2rem;
    position: relative;
    top: 0.06rem;
    text-align: left;
    font-weight: 600;

    img {
      padding: 0.4rem 0 0.4rem;
    }
  }

  .name-detail {
    font-size: 0.9rem;
    font-weight: 100;
    @media only screen and (max-width: 640px) {
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 440px) {
      font-size: 0.7rem;
    }
  }

  .value {
    font-family: Rift;
    font-size: 2rem;
  }

  .proposed-payment-tile-container {
      width: 90%;
  }

  .estimated-payment-amount {
    border-left: 1px solid $grey;
    border-bottom: 1px solid $grey;
    border-top: 1px solid $grey;
    background-color: $light-grey;
    padding: 1rem;
    position: relative;
    z-index: 20;
    top: 1px;
    .name-detail, .value {
      color: $grey;
    }

    &::after {
      content: "";
      position: absolute;
      right: -20px;
      bottom: 0;
      top: 0;
      width: 0;
      height: 0;
      border-left: 20px solid $light-grey;
      border-top: 62px solid transparent;
      border-bottom: 62px solid transparent;
      @media only screen and (max-width: 768px) {
        border-left: 20px solid $light-grey;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        right: -20px;
        top: 0;
      }
    }
  }

  .new-payment-amount {
    background-color: white;
    color: black;
    border-right: solid 1px $grey;
    border-bottom: solid 1px $grey;
    border-top: 1px solid $grey;
    padding: 1rem;
    position: relative;
    top: 1px;
  }

  .img-container {
    padding: 0.4rem 0 0.4rem;
    position: relative;
    height: auto;
    max-height: 4rem;
  }
}
