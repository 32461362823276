.radio-button-input {
  font-size: 1rem;
  padding-left: 0.6rem;

  .radio-question {
    padding-bottom: 0.5rem;
    margin-top: 1.5rem;
    @media only screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }

  input {
    cursor: pointer;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
    position: relative;
    padding-left: 2.5rem;
    cursor: pointer;
    padding-top: 0.3rem;
    line-height: 1rem;
    display: inline-block;
    @media only screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.06rem solid #C8CCD4;
    border-radius: 100%;
    background: white;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 0.9rem;
    height: 0.9rem;
    background: $light-blue;
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
