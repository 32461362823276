.steps-icon {
  width: 2.4375rem;
}

.left-side-margin-top {
  margin-top: 6.5rem;
  @media only screen and (max-width: 768px) {
    &.get-your-evaluation {
      margin-top: 6rem;
    }
  }
}

.right-side-margin-top {
  margin-top: 4rem;
  @media only screen and (max-width: 768px) {
    &.based-on {
      margin-top: 3rem;
    }

    &.you-will-receive {
      margin-top: 2.5rem;
    }

    &.debt-adjustment {
      margin-top: 1rem;
    }
  }
  @media only screen and (max-width: 640px) {
    &.based-on {
      margin-top: 2rem;
    }

    &.you-will-receive {
      margin-top: 0.5rem;
    }

    &.debt-adjustment {
      margin-top: 0;
    }
  }
}
