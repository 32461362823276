.bank-connection-tile {
  background: white;
  border-radius: 1rem;
  max-width: 730px;
  height: auto;
  margin: 2rem auto;

  .account-info {
    color: black;
    font-family: Arial, Helvetica, sans-serif;
  }
}
