@import '../../common/SearchBar/search-bar';
@import '../SummaryComponents/BorrowerTile/borrower-tile';
@import '../../common/SearchFilter/search-filter';
@import './SummaryFilterComponent/summary-filter-component';

.summary-container {
  position: relative;
  top: -150px;

  @media only screen and (max-width: 640px) {
    top: -220px;
  }
}
