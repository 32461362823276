.comment-box-text-area-input {
  .input-container {
    textarea {
      -webkit-appearance: none;
      width: 100%;
      font-family: inherit;
      padding: 1.25rem 0.55rem;
      height: 100%;
      font-size: 1rem;
      font-weight: 100;
      border: 0.06rem solid $medium-grey;
      background: white;
      border-radius: 0;
      transition: all .15s ease;
      border-radius: 4px;
      margin-top: -1rem;
      resize: none;

      &:not(:placeholder-shown) {
        +span {
          color: $grey;
          transform: translateY(-.5rem) scale(.75);
          font-size: 1rem;
          top: -1rem;
        }
      }

      &:focus {
        outline: none;
        border-color: $primary-blue;
        padding: 1.2rem 0.25rem 0.55rem 0.55rem;
        +span {
          color: $primary-blue;
          transform: translateY(-2.1rem) scale(.75);
          top: 2rem;
        }
        +.border {
          transform: scaleX(1);
        }
      }

      &:not(:focus-within){
        padding: 1.2rem 0.25rem 0.55rem 0.55rem;
      }
    }

    &.text-area {
      .label {
        top: -7.5rem;
      }
    }
  }
}
