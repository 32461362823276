
@keyframes carousel-animation {
  0% {
    opacity: 0;
    transform: scale();
    transform-origin: left;
    filter: blur(1.5rem);
    position: relative;
  }

  50% {
    opacity: .5;
    filter: blur(4px);
  }

  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.carousel-animation-1 {
  animation: carousel-animation .5s linear forwards;
}

.carousel {
  overscroll-behavior-y: contain;
  will-change: transform;
  touch-action: pan-y;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  @media only screen and (max-width: 640px) {
    width: 100%;
  }
}

.carousel-indicators {
  list-style: none;
  margin: auto;
  padding: 0;
  position: relative;
  text-align: center;
  z-index: 10;
  top: 100%;
  cursor: pointer;
  font-size: 2rem;
  text-overflow: ellipsis;
}

.carousel-selected {
  color: $primary-blue;
}

.directional-arrows {
  display: flex;
  align-items: center;
  justify-content: center;

  .arrow {
    background: url('../../../assets/icons/carousel-directional-arrow_icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 32px;
    cursor: pointer;
    outline: none;

    &.left {
      transform: rotate(180deg);
    }

    &.right {
      float: right;
    }
  }
}
