.debt-relief-hero {
  background-image: url('../../../../assets/images/hero/white-waves_pattern.png');
  background-repeat: repeat;
  height: auto;
  text-align: center;

  h1 {
    font-family: 'Montserrat-black';
    font-style: italic;
    color: $secondary-blue;
    @media only screen and (max-width: 640px) {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  h2 {
    font-family: 'Montserrat-light';
    @media only screen and (max-width: 640px) {
      line-height: 1.5rem;
    }
  }

  .video {
    color: $secondary-blue;
    float: left;
    @media only screen and (max-width: 640px) {
      float: none;
    }
  }

  .bbb-logo {
    width: 120px;
    height: 70px;
  }
}
