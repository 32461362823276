.payment-summary-graphic {
  svg {
    display: block;
    margin: auto;
  }

  .total-value {
    font-size: 3rem;
  }

  .payments-summary-details {
    font-size: .75rem;
    text-align: center;
    .color-green {
      color: $green;
    }
  }
}
