.offer-details {
  .current-balance-box {
    max-width: 450px;
    padding: 0 20px;

    p {
      border-bottom: 1px solid $grey;
      padding: 10px 0;
    }
  }

  .blue-box {
    border: 1px solid $primary-blue;
    background-color: #EBF1F6;
    max-width: 450px;
    padding: 10px 20px 20px;
    margin-top: 20px;

    p {
      border-bottom: 1px solid $primary-blue;
      padding: 10px 0;
    }
  }
}

.no-border {
  border-bottom: none;
}

