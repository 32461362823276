.react-tabs {
  -webkit-tap-highlight-color: transparent;
  position: relative;
  top: -70px;
  @media only screen and (max-width: 768px) {
    top: 0;
  }

  ul {
    li {
      font-size: 0.9rem;
      @media only screen and (max-width: 1279px) {
        font-size: 0.8rem;
      }
    }
  }

  &__tab-list {
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 20px 12px;
    cursor: pointer;
    padding-top: 1rem;
    color: white;
    @media only screen and (max-width: 1279px) {
      padding: 20px 8px;
    }

    @media only screen and (max-width: 1024px) {
      color: black;
    }

    @media only screen and (max-width: 768px) {
      padding: 10px;
    }

    &--selected {
      background: #fff;
      color: $primary-blue;
      border-radius: 5px 5px 0 0;

      li {
        color: $primary-blue!important;
      }
    }

    &--disabled {
      color: $grey;
      cursor: default;
    }

    &:focus {
      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
