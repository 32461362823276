.loading-bg {
  background-color:rgba(255, 255, 255, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: visible;
}

.logo-container {
  max-width: 200px;
  position: relative;
  top: 40%;
  display: block;
  margin: auto;

  p {
    text-align: center;
  }

  svg {
    display: block;
    margin: auto;
  }
}

 @keyframes g-animation {
  0% {
    stroke-dashoffset: -2000;
    filter: blur(10px);
    opacity: 1;
  }

  30% {
    fill: transparent;
  }

  50% {
    filter: blur(5px);
   }

  100% {
    fill: #0079db;
  }
}

.g-logo-animation {
  animation: g-animation 1.2s infinite;
  stroke-dasharray: 8000;
  stroke-dashoffset: 1000;
}

.g-icon {
  fill: transparent;
  stroke: #0079db;
  stroke-width: 3;
}
