@import '../../../common/SearchFilter/search-filter';

.debts-container {
  max-width: 500px;
  height: auto;
  margin: auto;

  .debt-summary-tile {
    background-color: white;
    border: 1px solid $grey;

    .name-detail {
      font-size: 0.9rem;
      text-align: right;
      margin-top: 1rem;
      color: $grey;
    }

    .value {
      font-family: Rift;
      font-size: 2rem;
      text-align: right;
      margin-top: -0.6rem;
      padding-bottom: 0.5rem;
    }

    .img-container {
      padding: 0.4rem 0 0.4rem;
      position: relative;
      top: 1rem;
      max-height: 4rem;
    }
  }

  .account-number-position {
    position: relative;
    top: 1.8rem;
    font-size: 0.9rem;
  }

  .value {
    .debt-icons {
      display: inline;
      margin-right: .2rem;
    }
  }

  .details {
    padding: 0 0.5rem;
  }
  .details ul.leaders span:first-child {
    background-color: white;
  }

  .details ul.leaders span + span {
    background: white;
  }
}
