@import './Hero/hero';
@import './LegalDisclosure/legal-disclosure';

.debt-analysis {
  .hero {
    h1 {
      font-family: "Helvetica-Neue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif;
      font-size: 3rem;
      line-height: 3.5rem;
      @media only screen and (max-width: 640px) {
        font-size: 2rem;
        line-height: 2.6rem;
        padding-top: 40px;
      }
    }
  }

  h2 {
    font-family: "Helvetica-Neue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 1.8rem;
  }

  .call-to-action-button {
    border-radius: 0.5rem;
    background-color: $orange;
    box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.1);
    color: white;
    padding: 1rem;
    width: 177px;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: $dark-orange;
      color: white;
    }

    a {
      &:hover {
        color: white;
      }
    }
  }
}
