@import './variables';
@import '../pages/customer-service-portal/customer-service-portal.scss';
@import '../components/portal/Alerts/alerts.scss';
@import '../components/customer-portal/IncomeAndCheckingBalanceCalendar/income-and-checking-balance-calendar';
@import '../components/customer-portal/AlertModal/alert-modal';
@import '../components/brochure/common/Hero/hero';
@import '../components/brochure/HardshipHowItWorks/hardshipHowItWorks';
@import '../components/common/Footer/footer';
@import '../components/common/CreditGenieHeader/credit-genie-header';
@import '../components/application/application';
@import '../components/application/common/ProgressBar/progressBar';
@import '../components/brochure/common/SubHero/subHero';
@import '../components/brochure/Accordion/accordion';
@import '../pages/application/BankConnection/bankConnection';
@import '../components/brochure/common/Navigation/navigation';
@import '../pages/offers/OfferDetail/offer-details';
@import '../pages/offers/ProposedPaymentSummary/proposed-payment-summary';
@import '../pages/offers/ProposedPaymentSummary/proposed-payment-tile';
@import '../components/common/Loading/loading';
@import '../components/common/ReactTabs/react-tabs';
@import '../components/common/CreditScore/credit-score';
@import '../components/common/CreditScoreCalendar/credit-score-calendar';
@import '../pages/portal/SignIn/sign-in';
@import '../pages/offers/CallUs/call-us';
@import '../pages/application/Income/IncomeSummary/income-summary';
@import '../pages/application/Income/ProvideMonthlyIncome/provide-monthly-income';
@import '../components/offers/LenderOfferTiles/lender-offer-tile';
@import '../components/portal/ResetPasswordValidation/reset-password-validation';
@import './nfcc.scss';
@import '../components/common/BasicLoading/basic-loading';
@import '../components/brochure/common/HardshipHero/hardship-hero';
@import '../components/brochure/common/PlainHeader/plain-header';
@import '../components/common/DebtSummaryComponent/debt-summary-component';
@import '../components/common/DebtSummaryComponent/DebtSummaryTile/debt-summary-tile';
@import '../components/common/DateIcon/date-icon';
@import '../components/common/ColorIconDetail/color-icon-detail';
@import '../pages/offers/Success/success';
@import '../pages/portal/portal';
@import '../pages/customer-portal/customer-portal';
@import '../components/landing-pages/landing-pages';
@import '../components/common/common.scss';
@import '../pages/application/AboutYou/about-you';
@import '../pages/pages';

html {
  min-height: 100%;
  width: 100%;
  background-color: $light-grey;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

.body-container {
  min-height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  &:hover {
    color: $secondary-blue;
  }
}

p {
  font-weight: 100;
}

h1 {
  font-family: 'Rift';
  line-height: 4rem;
  padding-bottom: 1rem;
  padding-top: 6rem;

  @media only screen and (max-width: 640px) {
    line-height: 3rem;
  }
}

h2 {
  font-family: 'Rift';
  line-height: 2.5rem;
  font-size: 1.5rem;
  @media only screen and (max-width: 640px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.section-header {
  font-family: 'Rift';
  line-height: 2.5rem;
  font-size: 1.5rem;
  @media only screen and (max-width: 640px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

h3 {
  text-transform: uppercase;
  font-weight: 700;
}

img {
  &.center {
    display: block;
    margin: 0 auto;
  }
}

.call-to-action {
  background-color: $primary-blue;
  color: white;
  cursor: pointer;
  max-width: 22rem;

  &:hover {
    background-color: $secondary-blue;
    color: white;
  }
}

.ko-call-to-action {
  border: 1px solid $primary-blue;
  color: $primary-blue;
  background-color: white;
  padding: 1rem 0rem;
  cursor: pointer;

  &:hover {
    background-color: $light-grey;
  }
}

.caps {
  text-transform: uppercase;
}

.bg-blue {
  background-color: $dark-blue;
}

.bg-light-blue {
  background-color: $primary-blue;
}

.bg-dark-blue {
  background-color: $dark-blue;
}

.bg-primary-blue {
  background-color: $light-blue;
}

.color-blue {
  color: $primary-blue;
}

.rift {
  font-family: 'Rift';
  letter-spacing: 0.1rem;
}

.divider {
  border-bottom: 1px solid #e5e5e5;
  padding-top: 0.2rem;
  font-size: 3rem;
}

.clear {
  clear: both;
}

.success {
  background-color: #deffe7;
  color: #52ba6f;
  border: 0.0625rem solid #52ba6f;
  border-radius: 0.3rem;
  margin: 0 auto;
  padding: 1.25rem;
  text-align: center;
}

.failure {
  background-color: #f8e4e4;
  color: $red;
  border: 0.0625rem solid $red;
  border-radius: 0.3rem;
  margin: 0 auto;
  padding: 1.25rem;
  text-align: center;
}

.form-failure {
  background-color: #f8e4e4;
  color: $red;
  border: 0.0625rem solid $red;
  border-radius: 0.3rem;
  margin: 1rem auto;
  text-align: center;
}

.green-text {
  color: $green;
}

.details {
  display: none;
  color: $grey;

  li {
    color: black;
  }

  ul.leaders {
    width: auto;
    padding: 0;
    overflow-x: hidden;
    list-style: none;
  }

  ul.leaders li:before {
    float: left;
    width: 0;
    white-space: nowrap;
    content: '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . '
      '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . ';
  }

  ul.leaders span:first-child {
    padding-right: 0.33em;
    background: #f0f8ff;
  }

  ul.leaders span + span {
    float: right;
    padding-left: 0.33em;
    background: #f0f8ff;
    display: block;
    font-weight: bold;
  }

  p {
    padding: 0.2rem 0;
    font-size: 0.8rem;
  }

  &.active {
    display: block;
  }
}

.customer-portal {
  .footer {
    @media only screen and (max-width: 640px) {
      position: relative;
    }
  }
}

.red-text {
  color: $vibrant-pink;
}

// Global graph styling //
.rv-discrete-color-legend-item__title {
  font-size: 12px;
  display: inline-block;
  opacity: 0.5;
}

.rv-xy-plot__axis__tick__text {
  color: $grey;
  font-size: 12px;
  opacity: 0.5;
}

.rv-xy-plot__axis__ticks {
  overflow: visible;
}

// Custom font //
@font-face {
  font-family: 'Rift';
  src: url('../assets/fonts/rift-bold.otf') format('opentype');
}

// Custom text style //
.text-blue-custom {
  color: $secondary-blue;
}

.bg-blue-custom {
  background-color: $primary-blue;
}

.border-blue-custom {
  border-color: $primary-blue;
}

// Tooltip styling //
.tooltip {
  position: relative;
  top: -72px;
  left: 179px;
  line-height: 1.4;
  color: #797c80;
  z-index: 2;
  @media only screen and (max-width: 640px) {
    left: 4px;
    top: -95px;
  }

  &::after {
    content: '';
    width: 14px;
    height: 14px;
    background-color: #f4f9fe;
    border-style: solid;
    border-color: $primary-blue;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    display: block;
    margin: -7px auto 0;
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.05);
    @media only screen and (max-width: 640px) {
      position: relative;
      left: 175px;
    }

    @media only screen and (max-width: 375px) {
      left: 154px;
    }
  }

  &.tooltip--fixed-width {
    width: auto;
    right: 0;
  }

  .tooltip__text-block {
    padding: 10px 12px;
    background-color: #f4f9fe;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px $primary-blue;
    border-radius: 2px;
  }
}

.double-line {
  .tooltip {
    top: -97px;
  }
}

.triple-line {
  .tooltip {
    top: -117px;
  }
}

.medical-expenses {
  .tooltip {
    @media only screen and (max-width: 375px) {
      top: -141px;
    }
  }
}

.miscellaneous-expenses {
  .tooltip {
    @media only screen and (max-width: 375px) {
      top: -141px;
    }
  }
}

.text-tooltip {
  font-weight: bold;
  font-size: 0.75rem;
  color: $primary-blue;
  cursor: pointer;
}

.application-heading-letter-spacing {
  line-height: 24px;
  margin-bottom: 10px;
}

.offer-status {
  font-weight: 600;
  text-align: right;
  padding-right: 1rem;
  margin-top: 0.5rem;

  &.accepted {
    color: $green;
  }

  &.pending {
    color: $orange;
  }

  &.declined {
    color: $red;
  }
}

.radio-tooltip-adjusments {
  &-dependents {
    position: relative;
    .img-adjust {
      position: relative;
      left: -140px;
      @media only screen and (max-width: 375px) {
        left: -62px;
      }
    }
    .tooltip.tooltip--fixed-width {
      position: absolute;
      width: 100%;
      top: -95px;
      left: 185px;
      @media only screen and (max-width: 640px) {
        top: -95px;
        left: 148px;
        width: 90%;
      }
      @media only screen and (max-width: 375px) {
        left: 130px;
      }

      &::after {
        @media only screen and (max-width: 640px) {
          left: 0;
        }
        @media only screen and (max-width: 414px) {
          left: 55px;
        }
        @media only screen and (max-width: 375px) {
          left: 50px;
        }
      }
    }
  }

  &-home {
    position: relative;

    img {
      position: relative;
      left: -185px;
      @media only screen and (max-width: 375px) {
        left: -66px;
      }
    }

    .tooltip.tooltip--fixed-width {
      position: absolute;
      width: 100%;
      left: 0;
      @media only screen and (max-width: 640px) {
        top: -75px;
      }

      &::after {
        @media only screen and (max-width: 640px) {
          left: 0;
        }
        @media only screen and (max-width: 375px) {
          left: 23px;
        }
      }
    }
  }

  &-spouse {
    position: relative;
    img {
      position: relative;
      left: -50px;
      @media only screen and (max-width: 375px) {
        left: -5px;
      }
    }
    .tooltip.tooltip--fixed-width {
      position: absolute;
      width: 100%;
      top: -120px;
      left: 135px;
      @media only screen and (max-width: 640px) {
        top: -145px;
        left: 30px;
        width: 90%;
      }

      &::after {
        @media only screen and (max-width: 640px) {
          left: 0;
        }
        @media only screen and (max-width: 414px) {
          left: 123px;
        }
        @media only screen and (max-width: 375px) {
          left: 130px;
        }
      }
    }
  }
}

.policy-tables {
  table {
    width: 750px;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
    border-collapse: collapse;
    margin: 20px 0;
  }

  tr:nth-of-type(odd) {
    background: $light-grey;
  }

  th {
    background: $primary-blue;
    color: white;
    font-weight: bold;
  }

  td,
  th {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
  }
}

.rift-it {
  font-family: 'Rift';
}

.no-highlight {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
