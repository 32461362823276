.debt-score-calendar {
  position: relative;
  padding-bottom: 2rem;
  @media only screen and (max-width: 640px) {
    left: 0.5rem;
  }

  svg {
    width: 100%;
    height: 150px;
  }
  .path {
    fill: none;
    stroke: none;
    stroke-width: 1;

    .path-container {
      text {
        &.months {
          font-size: 12px;
          fill: $light-grey-color;
        }

        &.score-range {
          font-size: 12px;
          fill: $light-grey-color;
        }
      }

      rect {
        &.clips {
          width: 4px;
          height: 17px;

          &.red-clip {
            fill: $vibrant-pink;
          }

          &.orange-clip {
            fill: $orange;
          }

          &.yellow-clip {
            fill: $yellow;
          }

          &.green-clip {
            fill: $green;
          }

          &.light-blue-clip {
            fill: $primary-blue;
          }
        }

        &.bars {
          width: 330px;
          height: 17px;
          fill-opacity: 0.1;

          &.red-bar {
            fill: $vibrant-pink;
          }

          &.orange-bar {
            fill: $orange;
          }

          &.yellow-bar {
            fill: $yellow;
          }

          &.green-bar {
            fill: $green;
          }

          &.light-blue-bar {
            fill: $light-blue;
          }
        }
      }

      circle {
        fill: white;
        &.one {
          animation: opacity-transition .15s linear;
        }

        &.two {
          animation: opacity-transition .25s linear;
        }

        &.three {
          animation: opacity-transition .35s linear;
        }

        &.four {
          animation: opacity-transition .45s linear;
        }

        &.five {
          animation: opacity-transition .55s linear;
        }

        &.six {
          animation: opacity-transition .65s linear;
        }
      }

      line {
        stroke: white;
        stroke-linecap: square;
        &.one {
          stroke-dasharray: 800;
          animation: dash .1s linear;
        }

        &.two {
          stroke-dasharray: 800;
          animation: dash .2s linear;
        }

        &.three {
          stroke-dasharray: 800;
          animation: dash .3s linear;
        }

        &.four {
          stroke-dasharray: 800;
          animation: dash .4s linear;
        }

        &.five {
          stroke-dasharray: 800;
          animation: dash .5s linear;
        }
      }
    }
  }

  @keyframes dash {
    to {
      stroke-dashoffset: -500;
    }
  }

  @keyframes opacity-transition {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
