@import '../../components/application/common/CheckboxInput/checkBoxInput';
@import '../../components/application/common/CommentBoxTextAreaInput/comment-box-text-area-input';
@import '../../components/application/common/DropdownInput/dropdownInput';
@import '../../components/application/common/TextInput/textInput';
@import '../../components/application/common/RadioButtonInput/radioButtonInput';
@import '../../components/application/ReviewTile/review-tile';
@import '../../components/application/IncomeTile/income-tile';
@import '../application/Agencies/agencies';

.grey-bg {
  background-color: $light-grey;
}

.form-width {
  max-width: 25rem;
}

.call-to-action {
  width: 100%;
}

.double-field-adjustment {
  .select-container {
    top: -0.5rem;
  }

  .right-field {
    position: relative;
    top: -0.5rem;
  }
}

input {
  &:disabled {
    background: #e9e9e9;
    color: #979797;
  }
}

.terms {
  color: $grey;
  position: relative;
  top: -23px;
  @media only screen and (max-width: 640px) {
    width: 90%;
  }
  @-moz-document url-prefix() {
    top: -35px;
  }
  left: 34px;

  a {
    color: $primary-blue;
    font-weight: 600;
  }
}

.consent-terms {
  top: -32px;
}

.policy {
  color: $primary-blue;
}

.validation {
  background-color: #f8e4e4;
  color: $red;
  border: 0.0625rem solid $red;
  padding: 10px;
  position: relative;
  top: -1.375rem;
  font-size: 0.8rem;

  &.top-adjustment {
    top: 0;
  }
}

.validation-warning {
  background-color: #f8eede;
  color: $orange;
  border: 0.0625rem solid $orange;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  font-size: 0.9rem;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

.radio-button-input {
  .validation {
    top: -0.6rem;
  }
}

.validation-border {
  border: 1px solid $red;
}

.input-tool-tip {
  color: $grey;
  font-size: 0.75rem;
  position: relative;
  left: 0.625rem;
}

.powered-by {
  width: 200px;
  margin: auto;
  display: flex;
  align-content: center;

  img {
    display: inline-block;
    width: 110px;
    height: 21px;
    position: relative;
    top: 4px;
  }
}

.life-events {
  .form-width {
    max-width: 80%;
    margin: 2rem auto;
    padding: 0 6rem;
    @media only screen and (max-width: 1024px) {
      max-width: 100%;
      margin: 0;
      padding: 2rem 1rem 0;
    }
  }
}

.gradient-arrow {
  @media only screen and (max-width: 640px) {
    position: relative;
    top: -1rem;
  }
}

.escrow {
  &.radio-tooltip-adjusments-home {
    .tooltip {
      &.tooltip--fixed-width {
        top: -6rem;
        @media only screen and (max-width: 640px) {
          left: 26rem;
          &::after {
            left: -6rem;
          }
        }
      }
    }
  }

  .escrow-tooltip {
    position: relative;
    top: 1.4rem;
    left: -7.5rem;
    @media only screen and (max-width: 640px) {
      left: -6rem;
    }

    @media only screen and (max-width: 392px) {
      left: -4rem;
    }
  }

  .estimated-housing-cost {
    @media only screen and (max-width: 400px) {
      span {
        font-size: 0.8rem;
        .label {
          font-size: 0.8rem;
        }
      }
    }
  }
}
