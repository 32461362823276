
.select-container {
  margin: auto;
  position: relative;
  width: 100%;

  .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.125rem;
    width: 100%;
    background: $primary-blue;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all .15s ease;
  }

  .label {
    position: absolute;
    top: 1.4rem;
    left: 0.625rem;
    font-size: 1rem;
    color: #2A2A2A;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all .2s ease;
    @media only screen and (max-width: 400px) {
      font-size: 0.9rem;
    }

    &:focus {
      top: 1.9rem;
    }

    &:active {
      top: 2rem;
    }
  }

  .on-blur {
    color: $grey;
    transform: translateY(-.5rem) scale(.75);
    font-size: 1rem;
  }

  img {
    position: relative;
    float: right;
    left: -1rem;
    top: 2rem;
  }

  select {
    background: white;
    border: 0.06rem solid $medium-grey;
    border-radius: 4px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 100;
    height: 3.2rem;
    -moz-appearance: none;
    padding: 1rem 0.55rem 0 0.55rem;
    transition: all .15s ease;
    -webkit-appearance: none;
    width: 100%;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: $primary-blue;

      +span {
        color: $primary-blue;
        transform: translateY(-2.1rem) scale(.75);
        top: 50px;
      }
    }
  }
}
