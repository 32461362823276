.debt-score {
  height: 170px;

  .svg {
    width: 313px;
    height: 184px;
    overflow: visible;

    g {
      &.g-container {
        stroke: none;
        stroke-width: 1;
        fill: none;
        fill-rule: evenodd;
      }
    }

    .debt-graphic-container {
      stroke-linecap: round;
      path {
        &.red {
          stroke: $vibrant-pink;
          animation: dash .1s linear;
        }

        &.orange {
          stroke: $orange;
        }

        &.yellow {
          stroke: $yellow;
        }

        &.green {
          stroke: $green;
        }

        &.light-blue {
          stroke: $light-blue;
        }

        &.black {
          stroke: black;
        }
      }
    }

    circle {
      stroke: $dark-blue;
      fill: $dark-blue;
    }

    text {
      &.debt-score-value {
        font-family: 'Rift';
        fill: white;
        letter-spacing: 0.4px;
        font-size:  48px;
      }

      &.debt-score-label {
        fill: white;
      }

      &.debt-score-date {
        font-size: 0.8rem;
        fill: $light-grey;
        text-align: center;
      }

      &.range {
        fill: white;
      }
    }
  }
}

.debt-score-tooltip {
  max-width: 350px;
  z-index: 10;
  margin: auto;
  height: 0px;

  img {
    position: relative;
    top: -3.6rem;
    left: 13.5rem;
    float: none;
  }

  .tooltip {
    top: -196px;
    left: 49px;
    @media screen and (max-width: 640px) {
      left: 0;
      &::after {
        left: 3rem;
      }
    }
  }
}
