.hero {
  background-image: url('../../../../assets/images/hero/homepage_hero.jpg');
  height: 30rem;
  @media only screen and (max-width: 640px) {
    background-image: url('../../../../assets/images/hero/mobile-hero/homepage_hero_mobile.jpg');
    height: 25rem;
    background-position-x: right;
  }

  @media only screen and (max-width: 640px) {
    h1 {
      font-size: 2.75rem;
      padding-top: 80px;
    }
  }

  ul {
    text-align: center;
    display: block;

    li {
      margin-right: 2.5rem;
      display: inline-block;
      font-weight: 600;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      color: white;

      @media only screen and (max-width: 640px) {
        margin-right: 1rem;
      }

      &:first-child {
        margin-left: 2.5rem;
        @media only screen and (max-width: 640px) {
          margin-left: 1rem;
        }
      }
    }
  }
}
