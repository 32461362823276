@import './DebtReliefHero/debt-relief-hero';

.debt-relief {
  .call-to-action-button {
    border-radius: 50px;
    margin: 0;
    background-color: $orange;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    padding: 1rem 0;
    font-weight: bold;
    @media only screen and (max-width: 640px) {
      margin: auto;
    }

    &:hover {
      background-color: $dark-orange;
    }
  }

  .ko-call-to-action {
    @media only screen and (max-width: 640px) {
      margin: 1rem auto;
    }
  }
}

