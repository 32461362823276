.transfer-application-tile {
  .call-to-action {
    max-width: 190px;
    font-size: 13px;
    height: 45px;

    &.archive-cta {
      background-color: $purple;
      margin-left: 1rem;

      &:hover {
        background-color: #791EB6;
      }
    }
  }

  .contact-table {
    .detail {
      color: $grey;
      font-size: 0.8rem;
      font-weight: normal;
    }
  }
}
