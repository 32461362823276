.borrower-tile {
  border-left: 0.5rem solid $primary-blue;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  max-width: 730px;
  height: auto;
  margin: 0 auto;
  position: relative;
  top: -35px;
  @media only screen and (max-width: 770px) {
    font-size: 0.75rem;
    top: -10px;
  }

  .divider {
    border-bottom: 1px solid #E5E5E5;
    width: 100%;
  }

  .divider-vertical {
    border-left: 1px solid #E5E5E5;
    height: 100%;
    margin: 0 1rem;
  }

  .detail-label {
    font-size: 0.7rem;
    color: $grey;
    text-align: right;
  }

  .detail-value {
    display: block;
    font-size: 0.8rem;
  }

  .borrower-details {
    color: $primary-blue;
    display: block;

    p:after {
      cursor: pointer;
      width: 40px;
      height: 40px;
      position: absolute;
      background-image: url('../../../../assets/icons/chevron.svg');
      background-repeat: no-repeat;
    }
  }

  .align-position {
    position: relative;
    top: 14px;
    @media only screen and (max-width: 640px) {
      top: 18px;
    }
  }

  .align-right-position {
    @media only screen and (max-width: 770px) {
      position: relative;
      top: 14px;
      padding-bottom: 10px;
    }
  }

  &-in-progress {
    border-left: 0.5rem solid $red;
  }

  &-call-scheduled {
    border-left: 0.5rem solid $dark-blue;
  }

  &-contacted {
    border-left: 0.5rem solid $dark-orange;
  }

  .enrolled {
    font-size: 0.8125rem;
    img {
      height: 22px;
      width: 22px;
    }
  }
}

@keyframes tile-animation {
  0% {
    opacity: 0;
    transform: scale(1, 0.5); /* Equal to scaleX(2) scaleY(0.5) */
    transform-origin: center;
    filter: blur(1.5rem);
  }

  50% {
    opacity: 0.5;
    filter: blur(4px);
  }

  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.borrower-tile-animation {
  animation: tile-animation 0.6s linear forwards;
}
