.footer {
  p {
    font-size: 0.75rem;
    color: #2A2A2A;
  }

  li {
    font-size: 0.75rem;
    color: #595959;
    padding-top: 0.5rem;
  }

  img {
    max-width: 9rem;
  }
}