@import './RenterAdvantageHero/renter-advantage-hero';
@import './HowCanWeHelp/how-can-we-help';
@import './HowItWorks/how-it-works';

.renter-advantage {
  h2 {
    @media only screen and (max-width: 640px) {
      font-size: 1.5rem;
    }
  }
}
