.proposed-payment-summary {
  .value {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .lender-guide-cols {
    max-width: 820px;
    display: flex;
    margin: auto;
    @media only screen and (max-width: 1529px) {
      flex: none;
      display: block;
      margin: auto;
    }

    .cols {
      width: 50%;
      @media only screen and (max-width: 1529px) {
        width: 100%;
      }
    }
  }
}
