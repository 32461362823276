.ko-call-to-action {
  background-color: transparent;
  color: $secondary-blue;
  border-radius: 50px;
  border: 1px solid $secondary-blue;
  cursor: pointer;
  max-width: 22rem;
  font-weight: bold;
  &:hover {
    background-color: $light-grey;
  }
}
