.review-application {
  .review-tile-container {
    margin: 20px auto 40px;

    ul.leaders span:first-child {
      background: white;
      @media only screen and (max-width: 640px) {
        font-size: 0.8rem;
      }
    }

    ul.leaders span + span {
      background: white;
    }

    &.application-component-container {
      width: 67%;
      @media only screen and (max-width: 1279px) {
        width: 100%;
      }
    }
  }

  .review-tile {
    border: 1px solid $grey;
    min-width: 22rem;
    max-width: 25rem;
    background-color: white;
    z-index: 5;
    @media only screen and (max-width: 640px) {
      min-width: 17rem;
    }

    .summary-type {
      font-size: 1rem;
      color: black;
      font-weight: bold;
      text-align: left;

      max-height: 20px;
    }

    .summary-amount {
      color: $green;
      font-size: 2.25rem;
      font-family: 'Rift';
      text-align: left;
      z-index: 10;
    }

    .edit-value {
      position: relative;
      top: -1.5rem;
      text-align: right;
      color: $primary-blue;
      width: auto;
      float: right;
      cursor: pointer;

      &.cancel {
        color: $red;
      }
    }

    .details {
      display: block;
      max-width: 21rem;

      @media only screen and (max-width: 640px) {
        ul.leaders {
          min-width: 15rem;
        }
      }

      @media only screen and (max-width: 400px) {
        ul.leaders {
          width: 19rem;
        }
      }

      @media only screen and (max-width: 365px) {
        ul.leaders {
          width: 18rem;
        }
      }

      @media only screen and (max-width: 353px) {
        ul.leaders {
          width: 15rem;
        }
      }
    }

    .call-to-action {
      width: 50%;
      cursor: pointer;
      padding: 0.7rem;
      height: 3rem;
    }

    .input-container {
      input {
        background-color: transparent;
        border-bottom: 1px solid black;
        border-top: none;
        border-left: none;
        border-right: none;
        width: 4.5rem;
        height: 25px;
        transition: none;
        position: relative;
        left: -7px;
        top: -3px;
        border-radius: 0;

        &:focus{
          padding: 0;
        }

        &:not(:focus-within) {
          padding: 0;
        }
      }
    }

    .cancel-value {
      color: $primary-blue;
      border: 1px solid $primary-blue;
      padding: 0.7rem;
      height: 3rem;
      text-align: center;

      &:hover {
        background-color: $light-grey;
      }
    }

  }

  .call-to-action {
    padding: 1rem 4rem;
  }

  .review-tile-row {
    width: 85%;
    margin: auto;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }
}
