.referral {
  .hero {
    background-image: url('../../../../assets/images/hero/referral-hero_desktop.jpg');
    height: 50rem;
    text-align: center;
    @media only screen and (max-width: 640px) {
      background-image: url('../../../../assets/images/hero/mobile-hero/referral-hero_mobile.png');
      height: 33rem;
    }
  }

  .powered-by {
    position: relative;
    top: 1rem;
  }

  .logo {
    width: 240px;
  }

  .nonprofit {
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }
}
