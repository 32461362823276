.contract-summary-questions {
    select {
        border: 1px solid $grey;
        outline: none;
    }

    .cancel-btn {
        border: 2px solid $primary-blue;
        background-color: white;
        color: $primary-blue;
    }

}