.plan-enrollment-summary {
  .enrolled {
    color: $green;
  }

  .edit {
    border-left: 1px solid #E5E5E5;
    font-weight: 400;
  }

  .payment-amount {
    color: $green;
    font-family: 'Rift';
    font-size: 3.75rem;
  }

  .call-to-action {
    padding: 1rem;
    max-width: 190px;
    font-size: 13px;
    height: 45px;
    outline: none;
    width: 100%;

    &.plan-enrollment-summary-cta {
      background-color: $green;

      &:hover {
        background-color: #52CB84;
      }
    }
  }
}

.modal-bg {
  background: rgba(0, 0, 0, 0.4);
  background-size: cover;

  .modal-form {
    width: 450px;
    border-radius: 0.3rem;

    .call-to-action {
      max-width: 100%;
    }

    .call-to-action-KO {
      border: 1px solid $primary-blue;
      color: $primary-blue;
      width: 100%;
    }
  }
}
