.provide-monthly-income {
  .input-container {
    input {
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 1px solid grey;
      background-color: transparent;
      font-size: 4rem;
      height: auto;
      text-align: center;
      padding-bottom: 0;
    }
  }
}
