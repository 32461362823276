.plain-header {
  height: 4rem;

  img {
    @media only screen and (max-width: 640px) {
      padding-left: 1rem;
    }
  }

  form {
    @media only screen and (max-width: 640px) {
      padding-right: 1rem;
    }
  }
}
