.edit-preferences {
  h3 {
    font-family: Rift;
    font-size: 18px;
    color: black;
  }

  a {
    color: $primary-blue;
  }

  .divider {
    padding: 0;
  }

  ul {
    li {
      padding-bottom: 0;
    }
  }
}

.customer-service {
  h3 {
    font-weight: normal;
  }
}
