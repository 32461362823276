.monthly-savings-amount {
    color: $green;
      font-size: 3.25rem;
      font-family: 'Rift';
      text-align: center;
      z-index: 10;
}

.new-monthly-payment-tile {
    max-width: 500px;
    height: auto;
    margin: 0 auto;
    position: relative;
    
    .grey-text {
      color: $grey;
    }

    h2 {
      font-size: 2.5rem;
    }
}