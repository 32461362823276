.back-button {
  position: relative;
  top: -9.5rem;
}

.box {
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 1rem;
  position: relative;
  top: -120px;
}

.borrower-summary-components {
  position: relative;
  top: -20px;

  h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: none;
    @media only screen and (max-width: 640px) {
      font-size: 0.9rem;
    }
  }

  .sub-text {
    font-size: 1rem;
    color: #979797;
    font-weight: 100;
    text-transform: none;
    padding-bottom: 1rem;
    @media only screen and (max-width: 640px) {
      font-size: 0.9rem;
    }
  }

  ul {
    li {
      color: $grey;
      padding-bottom: 0.7rem;
      @media only screen and (max-width: 640px) {
        font-size: 0.9rem;
      }

      &.black {
        color: black;
      }
    }
  }

  .ko-call-to-action {
    padding: .5rem 0;
    min-width: 17rem;
  }
}

.borrower-info {
  width: 24rem;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 70px;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }

  ul {
    li {
      color: $primary-blue;
    }
  }

  .label {
    font-size: .8rem;
    color: #979797;
    font-weight: 600;
  }

  .label-value {
    font-size: .9rem;
  }
}

.dynamic-info {
  width: 70%;
  margin-left: 2rem;
  margin-top: 0.9rem;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
  }

  .sub-nav {
    position: relative;
    top: -185px;
    @media only screen and (max-width: 1024px) {
      top: -127px;
    }

    @media only screen and (max-width: 736px) {
      top: -115px;
    }

    .button {
      background-color: $primary-blue;
      padding: 1rem 0;
      color: white;
      text-align: center;
      font-size: 1rem;
      min-width: 100px;
      margin: auto 1rem auto 0;
      &:first-child {
        margin-left: 0;
      }

      &:hover {
        background-color: $secondary-blue;
        a {
          color: white;
        }
      }

      @media only screen and (max-width: 1280px) {
        font-size: .8rem;
      }

      @media only screen and (max-width: 768px) {
        margin-left: 0;
        margin-top: .5rem;
      }

      @media only screen and (max-width: 736px) {
        padding: 1rem;
      }
    }
  }

  .box {
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 1rem;
    position: relative;
    top: -120px;
    min-height: 200px;
    @media only screen and (max-width: 1024px) {
      top: -115px;
    }

    @media only screen and (max-width: 736px) {
      top: -90px;
    }
  }
}

.blue-arrow-icon {
  width: 0.9rem;
  height: 0.95rem;
  display: inline-block;
}

.green-success {
  color: $green;
  font-weight: bold;
}

.orange-success {
  color: $dark-orange;
  font-weight: bold;
}

@keyframes contact-confirmation-animation {
  0% {
    opacity: 0;
    display: none;
    transform: translate(-10px, 0);
  }

  20% {
    opacity: 1;
    transform: translate(0, 0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.contact-confirmation-animation {
  animation: contact-confirmation-animation 1.5s ease-in-out;
}