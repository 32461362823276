.on-boarding {
  .footer {
    position: fixed;
    @media only screen and (max-width: 1024px) {
      position: relative;
      top: 15rem;
    }
    @media only screen and (max-width: 768px) {
      top: 0;
    }
    bottom: 0;
    width: 100%;
  }
}
