.faq {
  .question {
    border-bottom: 0.06rem solid $primary-blue;
    cursor: pointer;
    transition: background 0.3s;
    min-height: 3rem;
    max-height: 3.75rem;
    font-weight: bold;
    outline: none;

    img {
      position: relative;
      float: right;
      top: 0.5rem;
    }

    .chevron {
      transform: rotate(-180deg);
    }
  }
  .answer {
    display: none;
  }

  .answer-border {
    border-bottom: 0.06rem solid $primary-blue;
    padding-bottom: 1rem;
  }
}
