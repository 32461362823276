.renter-advantage-hero {
  background-image: url('../../../../assets/images/hero/white-waves_pattern.png');
  background-repeat: repeat;
  height: auto;
  padding-bottom: 4rem;
  text-align: center;
  @media only screen and (max-width: 640px) {
    height: 35rem;
  }

  .logo {
    width: 500px;
    @media only screen and (max-width: 640px) {
      width: 100%;
      padding: 0 1rem;
    }
  }
}

.powered-by {
  position: relative;
  top: 1rem;
  width: 100%;
  margin: auto;
  display: block;
}

.nonprofit {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

