.income-summary {
  padding-bottom: .5rem;

  .amount {
    font-family: 'Rift';
    font-size: 4rem;
    color: $green;
  }

  .call-to-action, .ko-call-to-action {
    width: 20%;
    padding: 1rem 0rem;
    @media only screen and (max-width: 640px) {
      width: 40%;
    }
  }

  .amount-description {
    position: relative;
    top: 12px;
    color: $grey;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    text-align: center;
  }

  ::-webkit-input-placeholder {
    color: $green;
  }

  ::-moz-placeholder {
    color: $green;
  }

  :-ms-input-placeholder {
    color: $green;
  }

  :-moz-placeholder {
    color: $green;
  }
}

.additional-income-question {
  img {
    position: relative;
    top: 2px;
  }
  .tooltip {
    top: -136px;
    left: -142px;
    min-width: 300px;
    position: absolute;

    @media only screen and (max-width: 640px) {
      left: -87px;
    }

    &::after {
      @media only screen and (max-width: 640px) {
        left: -55px;
      }
    }
  }
}

.header-line-height {
  line-height: 2rem;
  @media only screen and (max-width: 640px) {
    line-height: 1.5rem;
  }
}
