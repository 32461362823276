$duration: 1000;
.trophy-graphic {
  svg {
    display: block;
    margin: auto;
    width: 150px;
    height: auto;
    @media only screen and (max-width: 640px) {
      width: 120px;
    }
  }
}

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));
  @return $randomNum;
}

.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 175px;
  overflow: hidden;
  @media only screen and (max-width: 640px) {
    top: 20%;
  }
}

.confetti-piece {
  position: absolute;
  width: 8px;
  height: 16px;
  background: $orange;
  top: 0;
  opacity: 0;

  @for $i from 1 through 13 {
    &:nth-child(#{$i}) {
      left: $i * 7%;
      transform: rotate(#{randomNum(-80, 80)}deg);
      animation: makeItRain $duration * 1ms infinite ease-out;
      animation-delay: #{randomNum(0, $duration * .5)}ms;
      animation-duration: #{randomNum($duration * .7, $duration * 1.2)}ms
    }
  }

  &:nth-child(odd) {
    background: $primary-blue;
  }

  &:nth-child(even) {
    z-index: 1;
  }

  &:nth-child(4n) {
    width: 5px;
    height: 12px;
    animation-duration: $duration * 2ms;
  }

  &:nth-child(3n) {
    width: 3px;
    height: 10px;
    animation-duration: $duration * 2.5ms;
    animation-delay: $duration * 1ms;
  }

  &:nth-child(4n-7) {
    background: $vibrant-pink;
  }
}

@keyframes makeItRain {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: translateY(200px);
  }
}
