.customer-financials {
  h3 {
    text-transform: none;
  }

  .sub-text {
    color: #B2B2B2;
    font-size: .75rem;
  }

  .debt-score {
    height: auto;
    .svg {
      height: 150px;
    }
  }

  .financial-breakdown {
    .value {
      font-weight: bold;
      font-size: 1.5rem;

      &.green {
        color: $green;
      }

      &.red {
        color: $vibrant-pink;
      }
    }
  }

  .top-transactions {
    &.details {
      display: block;

      li {
        color: white;
      }

      ul.leaders span:first-child {
        background: $dark-blue;
        color: white;
      }

      ul.leaders span + span {
        background: $dark-blue;
        color: white;
      }
    }
  }

  .transaction-graphic {
    width: 200px;
  }

  .transaction-categories {
    .category-box {
      height: 12px;
      width: 12px;
      &.housing {
        background-color: $light-blue;
      }

      &.transportation {
        background-color: $primary-blue;
      }

      &.utilities {
        background-color: $dark-green;
      }

      &.food {
        background-color: $green;
      }

      &.medical {
        background-color: $vibrant-pink;
      }

      &.personal {
        background-color: $light-grey;
      }

      &.savings {
        background-color: #697F92;
      }

      &.other {
        background-color: $purple;
      }

      &.misc {
        background-color: $grey;
      }

      &.student-loans {
        background-color: $orange;
      }
    }
  }
// Debt Filter Adjustments
  .debt-summary-component {
    @media screen and (max-width: 768px) {
      top: -18px;
    }
  }

  .filter-contents {
    top: 30px;
  }

  .filter-header {
    margin-top: 2rem;
  }

  .results {
    color: white;
  }

  .search-bar {
    left: 0;
    @media screen and (max-width: 768px) {
      padding: 0;
      top: -70px;
    }
  }

  .filter-container {
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .filter-header {
    h1, h2 {
      font-size: 1rem;
      letter-spacing: 0;
    }
  }
}
