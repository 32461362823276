.alert-modal {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;

  &-bg {
    background-color: rgba(189, 185, 185, 0.5);
  }

  .call-to-action {
    background-color: $primary-blue;
    padding: 1rem 4rem;
    border-radius: 50px;
  }
}
