.video-modal {
  font-size: 1.5rem;
  outline: none;

  button {
    outline: none;
  }

  .video-bg {
    background-color:rgba(255, 255, 255, 0.9);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: visible;
  }

  .video-content {
    width: 800px;
    @media only screen and (max-width: 640px) {
      width: 100%;
      padding: 0 1rem;
    }
    outline: none;
    position: relative;
    top: 20%;
    display: block;
    margin: auto;
  }
}
