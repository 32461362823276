.portal-header {
  background-color: $dark-blue;
  height: 19rem;

  .container {
    max-width: auto;
  }

  img {
    display: inline-block;
    padding-left: 0.5rem;
    @media only screen and (max-width: 640px) {
      padding-left: 1rem;
    }
  }

  ul {
    li {
      color: white;
      &:last-child {
        border-bottom: none;
        text-align: left;
      }
    }
  }

  .logo {
    position: relative;
    @media only screen and (max-width: 1023px) {
      left: -2.1rem;
    }
  }

  .pad-left-mobile {
    @media only screen and (max-width: 1023px) {
      padding-left: 0.2rem;
    }
  }

  button {
    &:focus {
      outline: none;
    }
  }

  .m-menu {
    list-style-type: none;
    position: relative;
    text-align: center;
    z-index: 101;
    outline: none;
  }

  .m-menu.-active .m-menu__icon path {
    fill: #0b3895;
  }

  .m-menu.-active .m-menu__list {
    transform: scale(1);
  }

  .m-menu__list {
    position: absolute;
    width: 180px;
    text-align: left;
    z-index: 2;
    padding: 0;
    border-radius: 3px;
    background-color: #fff;
    transition: 0.25s ease all;
    transform: scale(0);
    transform-origin: 0 1;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }

  .m-menu__item {
    display: block;
    width: 100%;
    padding: 0.5rem;

    &:first-child {
      padding-top: 1rem;
      padding-bottom: 0;
    }

    &:last-child {
      padding-bottom: 1rem;
      border-bottom: none;
    }
  }

  .m-menu__item:hover {
    color: #0b3895;
  }

  .m-menu__link {
    width: 100%;
    padding: 4px;
    display: inline-block;
    color: $primary-blue;
    cursor: pointer;
  }

  .m-menu__link:hover {
    text-decoration: none;
  }

  .hamburger {
    display: none;
    z-index: 2px;
    @media only screen and (max-width: 1023px) {
      display: block;
    }
  }

  @keyframes mobile-nav-animation {
    0% {
      opacity: 0;
      left:-2000px;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }

  .mobile-nav {
    background-color: $dark-blue;
    color: white;
    position: absolute;
    top: 1.5rem;
    height: 100%;
    width: 80%;
    z-index: 2;
    animation-name: mobile-nav-animation;
    animation-duration: .3s;
    animation-timing-function: ease;
    animation-iteration-count: initial;

    li {
      border-bottom: 0.06rem solid $primary-blue;
      padding: 0.5rem 0;
      @media only screen and (max-width: 1024px) {
        margin-left: 1rem;
      }
    }

    .close-icon {
      position: relative;
      left: -1.2rem;
      top: -1.9rem;
    }
  }
}
