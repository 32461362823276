@import '../components/common/NFCCFooter/nfcc-footer';

$nfcc-dark-blue: #150C39;
$nfcc-light-blue: #85ADD6;
$nfcc-green: #9AC03F;


.nfcc {
  .header {
    background-color: $nfcc-dark-blue;
  }

  .call-to-action {
    background-color: $nfcc-light-blue;
    &:hover {
      background-color: #3C6497;
      color: white;
    }
  }

  .plaid-call-to-action {
    background-color: $nfcc-light-blue;
  }

  .ko-call-to-action {
    border: 1px solid $nfcc-light-blue;
    color: $nfcc-light-blue;
  }

  .bg-light-blue {
    background-color: $nfcc-green;
  }

  .color-blue {
    color: $nfcc-green;
  }

  .nfcc-blue {
    color: $nfcc-light-blue;
  }

  .nfcc-green {
    color: $nfcc-green;
  }

  .terms {
    a {
      color: $nfcc-light-blue;
    }
  }

  .input-container {
    .border {
      background: $nfcc-light-blue;
    }

    input {
      &:focus {
        border-color: $nfcc-light-blue;
        +span {
          color: $nfcc-light-blue;
        }
      }
    }
  }

  .checkbox {
    .container {
      input {
        &:checked ~ .checkmark {
          background-color: $nfcc-green;
        }
      }
    }
  }

  .radio-button-input {
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      background: $nfcc-green;
    }
  }

  .income-tile {
    .transaction-value {
      color: $nfcc-green;
    }
  }

  .income-summary {
    ::-webkit-input-placeholder {
      color: $nfcc-green;
    }

    ::-moz-placeholder {
      color: $nfcc-green;
    }

    :-ms-input-placeholder {
      color: $nfcc-green;
    }

    :-moz-placeholder {
      color: $nfcc-green;
    }
  }

  .amount {
    color: $nfcc-green;
  }

  .amount-description {
    color: $nfcc-green;
  }

  .review-application {
    .review-tile {
      .summary-amount {
        color: $nfcc-green;
      }

      .edit-value {
        color: $nfcc-light-blue;
      }

      .cancel-value {
        border: 1px solid $nfcc-light-blue;
        color: $nfcc-light-blue
      }
    }
  }

  .hardship-offer-tile {
    .new-payment-amount {
      background-color: $nfcc-green;
    }
  }

  .hardship-offer-tile  {
    .details ul.leaders span + span {
      background: #F6F8F1;
    }

    .details ul.leaders span:first-child {
      background: #F6F8F1;
    }

    .lender-details .active {
      background-color: #F6F8F1;
      border: 1px solid $nfcc-green;
    }
  }

  .progress-bar-contain {
    .visited-personal-information {
      background-image: url(../assets/icons/progress-bar/nfcc/personal-information_green_icon.svg);
    }

    .visited-verify-income-and-expenses {
      background-image: url(../assets/icons/progress-bar/nfcc/verify-income-and-expenses_green_icon.svg);
    }

    .visited-connect-bank-account {
      background-image: url(../assets/icons/progress-bar/nfcc/connect-bank-accounts_green_icon.svg);
    }

    .progress-title {
      color: $nfcc-green;
    }
  }

  .shield-stroke {
    stroke: $nfcc-green;
  }
}
