.debt-summary-component {
  color: black;
  @media only screen and (max-width: 768px) {
    position: relative;
    top: 55px;
  }

  h1 {
    position: relative;
    top: -20px;
  }
}
