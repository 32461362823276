.customer-expense-summary {
  .customer-portal {
    padding-bottom: 5rem;

    .expense-summary {
      h1 {
        padding-top: 0;
      }

      .amount {
        color: $vibrant-pink;
        font-family: 'Rift';
        font-size: 3rem;
        margin-top: -2.2rem;
      }
    }
  }

  .expense-tile-container {
    width: 70%;
    margin: auto;
    @media only screen and (max-width: 1279px) {
      width: 90%
    }

    @media only screen and (max-width: 768px) {
      width: 100%
    }
  }

  .review-application {
    .review-tile {
      .details {
        max-width: 23rem;
      }
    }
  }

  .box {
    top: -4rem;
    color: black;
    background-color: white;
  }

  .label-text {
    font-size: 0.9rem;
    color: $light-grey-color;

    span {
      &.color-green {
        color: $green;
      }

      &.color-red {
        color: $vibrant-pink;
      }
    }
  }
}
