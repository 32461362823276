.monthly-savings-amount {
  color: $green;
  font-size: 3.25rem;
  font-family: 'Rift';
  text-align: center;
  z-index: 10;

  &-subtext {
      color: $green;
  }
}

.new-monthly-payment-tile {
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  position: relative;
  
  .grey-text {
    color: $grey;
  }

  h2 {
    font-size: 2.5rem;
  }

  .success-details {
    .details {
      ul.leaders {
        span:first-child {
          background-color: $light-grey;
        }
        span + span {
          background-color: $light-grey;
        }
      }
    }
  }
}

.lender-carousel {
  .lender-offer-tile {
    .details {
      ul.leaders {
        span:first-child {
          background-color: white;
        }
        span + span {
          background-color: white;
        }
      }
    }

    .lender-details {
      p {
        &.label {
          position: relative;
          left: -1rem;
          z-index: 2;
        }
      }
      .active {
        background-color: white;
        border: none;
      }
    }
  }
}

.disclosure {
  color: $grey;
  font-size: 0.7rem;
  padding-top: 1rem;
}
