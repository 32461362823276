$colors: #0079DB, #0079DB, #0079DB, #0079DB;
$left: 25px;
$count: 40px;
$timing: 600ms infinite ease-in-out;
$delay: 75ms;

@mixin jelly($num1, $num2) {
  stroke: nth($colors, $num1);
  cx: $left + ($count * $num2);
  stroke-width: 3px;
  animation: jump $timing;
  opacity: .7;
  animation-delay: $delay * $num2;
}

@mixin floor($num2) {
  fill: #333333;
  opacity: .05;
  rx: 0;
  ry: 0;
  cx: $left + ($count * $num2);
  cy: 48px;
  animation: shadow $timing;
  animation-delay: $delay * $num2;
}

.sub-content {
  position: relative;
  width: 300px;
  left: -47px;
}

.contain {
  position: relative;
  top: 30%;
  width: 200px;
  display: block;
  margin: auto;
  overflow: visible;

  .text {
    text-align: center;
    position: relative;
    top: 60px;

    &.headline {
      font-size: x-large;
    }
  }

  svg {
    position: absolute;
    left: -10px;

    ellipse {
      transform-origin: center;
    }

    &:nth-of-type(1) ellipse {
      @include jelly(1, 0);
    }

    &:nth-of-type(2) ellipse {
      @include jelly(2, 1);
    }

    &:nth-of-type(3) ellipse {
      @include jelly(3, 2)
    }

    &:nth-of-type(4) ellipse {
      @include jelly(4, 3)
    }

    &:nth-of-type(6) ellipse {
      @include floor(0)
    }

    &:nth-of-type(7) ellipse {
     @include floor(1)
    }

    &:nth-of-type(8) ellipse {
      @include floor(2)
    }

    &:nth-of-type(9) ellipse {
      @include floor(3)
    }

  }
}

$stroke-reg: 3px;
$dist: 10px;

@keyframes jump {
  40% {
    transform: translateY($dist * 2) scale(1.3);
    opacity: .9;
  }
  40% {
    rx: $dist;
    ry: $dist;
    stroke-width: $stroke-reg;
  }
  45% {
    rx: $dist + 5;
    ry: $dist - 3;
    stroke-width: $stroke-reg + 1;
  }
  55% {
    rx: $dist;
    ry: $dist;
  }
}

@keyframes shadow {
  45% {
    opacity: .15;
    rx: $dist;
    ry: $dist - 7;
    transform: translateY($dist - 10) scale(1.3);
  }
}

$anim-speed: 50s;

.tips {
  &-container {
    position: relative;
    top: 40%;
  }
  &-box {
    display: grid;
  }
  grid-column: 1;
  grid-row: 1;
  opacity: 0;
  animation: fact-in $anim-speed infinite ease-in-out;
  animation-fill-mode:forwards;
    @for $i from 1 through 5 {
      &:nth-child(#{$i}){
        animation-delay: ($i - 1) * 10s;
      }
    }
}

@keyframes fact-in{
  0%{
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
 20% {
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}
