.input-container {
  position: relative;
  margin: auto;
  width: 100%;

  .label {
    position: absolute;
    top: -0.4rem;
    left: 0.625rem;
    font-size: 1rem;
    color: #2A2A2A;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all .2s ease;
    @media only screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }

  .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.125rem;
    width: 100%;
    background: $primary-blue;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all .15s ease;
  }

  input {
    -webkit-appearance: none;
    width: 100%;
    font-family: inherit;
    padding: 1.25rem 0.55rem;
    height: 3.2rem;
    font-size: 1rem;
    font-weight: 100;
    border: 0.06rem solid $medium-grey;
    background: white;
    border-radius: 0;
    transition: all .15s ease;
    border-radius: 4px;

    &:not(:placeholder-shown) {
      +span {
        color: $grey;
        transform: translateY(-.5rem) scale(.75);
        font-size: 1rem;
        @media only screen and (max-width: 400px) {
          font-size: 0.8rem;
        }
      }
    }

    &:focus {
      outline: none;
      border-color: $primary-blue;
      padding: 1.2rem 0.25rem 0.55rem 0.55rem;
      +span {
        color: $primary-blue;
        transform: translateY(-2.1rem) scale(.75);
        top: 20px;
      }
      +.border {
        transform: scaleX(1);
      }
    }

    &:not(:focus-within){
      padding: 1.2rem 0.25rem 0.55rem 0.55rem;
    }
  }
}
