.offer-summary {
  .offer-details {
    display: block;
    margin: 0 auto;
    max-width: 500px;

    ul.leaders {
      display: grid;

      span:first-child {
        background-color: white;
      }

      span + span {
        background-color: white;
      }
    }
  }

  .green-text {
    color: $green;
  }

  li.disqualification-reason {
    color: $red;

    &:before {
      content: "-";
      margin-right: 5px,
    }
  }
}
