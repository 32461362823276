@import './Hero/hero';
@import './HowItWorks/how-it-works';

.bg-light-grey {
  background-color: #F8F8F8;
}

.border-right {
  border-right: 1px solid $grey;
}
