.search-bar {
  position: relative;
  top: -75px;
  width: 40%;
  left: -20px;
  max-width: 430px;
  @media only screen and (max-width: 640px) {
    padding: 0 60px;
    width: 100%;
    top: -40px;
    left: 0;
  }

  input {
    padding-left: 3rem;
    border: 1px solid #E5E5E5;
    border-radius: 100px / 100px;
    @media only screen and (max-width: 340px) {
      width: 90%;
    }
  }

  button {
    border: none;
    outline: none;

    &.filter-icon {
      position: relative;
      top: 3rem;
      left: -0.3rem;
    }

    &.search-icon {
      position: relative;
      top: -3.1rem;
      left: 30px;
      float: right;
    }
  }
}
