.date-picker {
  background: white;
  border: .06rem solid $medium-grey;
  border-radius: .25rem;
  color: white;
  height: 33px;
  margin: 0 1rem 0 1rem;
  outline: none;
  text-align: center;
  user-select: none;
  cursor: pointer;
  min-width: 200px;

  &:focus {
    border-color: #0179DB;
  }
}

.date-picker-display {
  position: absolute;
  left: 67px;
  top: 109px;
  text-align: center;
  pointer-events: none;
  width: 200px;
}

.clear-date {
  color: red;
  border-left: 1px solid $grey;
  padding-left: 1rem;

  &:focus {
    outline: none;
  }
}
