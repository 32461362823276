.navigation {
  .hamburger {
    display: none;
    @media only screen and (max-width: 1023px) {
      display: block;
    }
  }

  .logo {
    position: relative;
    @media only screen and (max-width: 1023px) {
      left: -1.1rem;
      top: 0.3rem;
    }
  }

  @keyframes mobile-nav-animation {
    0% {
      opacity: 0;
      left:-2000px;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }
  .mobile-nav {
    background-color: $dark-blue;
    color: white;
    position: absolute;
    top: -0.5rem;
    height: 100%;
    width: 80%;
    z-index: 2;
    animation-name: mobile-nav-animation;
    animation-duration: .3s;
    animation-timing-function: ease;
    animation-iteration-count: initial;

    li {
      padding: 0.5rem 0;
    }

    .close-icon {
      position: relative;
      left: -1.2rem;
      top: -1.9rem;
    }
  }
}
