.debt-analysis {
  .hero {
    background-image: url('../../../../assets/images/hero/invitation-lp_hero.jpg');
    height: 50rem;
    text-align: center;
    @media only screen and (max-width: 640px) {
      background-image: url('../../../../assets/images/invitation-lp_hero_mobile.jpg');
      height: 24rem;
    }

    .sub-headline {
      font-weight: 300;
      @media only screen and (max-width: 640px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
