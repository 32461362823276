@import './CustomerAlerts/customer-alerts';
@import './CustomerBudget/customer-budget';
@import './CustomerDebtAnalysis/customer-debt-analysis';
@import './CustomerExpenseSummary/customer-expense-summary';
@import './CustomerFinancials/customer-financials.scss';
@import './CustomerOffersSummary/customer-offers-summary';
@import './CustomerSettings/customer-settings.scss';
@import './CustomerSummary/customer-summary';
@import '../../components/customer-portal/BankConnectionTile/bank-connection-tile';
@import '../../components/customer-portal/BudgetBarGraph/budget-bar-graph';
@import '../../components/customer-portal/common/Header/header';
@import '../../components/customer-portal/DebtScore/debt-score';
@import '../../components/customer-portal/DebtScoreCalendar/debt-score-calendar';
@import '../../components/customer-portal/FinancialSummaryCalendar/financial-summary-calendar';
@import '../../components/customer-portal/PaymentSummary/PaymentSummaryGraphic/payment-summary-graphic.scss';

.customer-portal {
  background-color: $dark-blue;
  color: white;

  h1 {
    padding-top: 0;
    padding-bottom: 0;
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
  }

  .sub-text {
    color: #DFDFDF;
    font-size: 0.75rem;
  }

  h2 {
    letter-spacing: 0.1rem;
  }

  .divider {
    color: #B2B2B2;
  }

  .amount {
    font-size: 3rem;
    padding-top: 1rem;
  }

  .customer-portal-input {
    background-color: #49a4d5;
    border-radius: 24px;
    margin-top: 0;
    min-width: 160px;
    outline: none;
    padding: 0.75rem 2.5rem;
    width: auto;

    &:hover {
      background-color: $secondary-blue;
      color: white;
    }
  }

  .customer-portal-title {
    font-family: "Rift";
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 25px;
    letter-spacing: 1.23px;
    text-align: center;

    &.summary-components {
      font-size: 1.2rem;
    }
  }

  .divider {
    border-bottom: 1px solid #979797;
  }

  .green {
    color: $green;
  }

  .customer-payments {
    .details {
      display: block;
      color: white;

      ul.leaders span:first-child {
        background: $dark-blue;
      }

      ul.leaders span + span {
        background: $dark-blue;
      }

      li {
        color: white;
      }
    }
  }

  & .lender-carousel {
    color: black;

    & .nfcc-blue {
      color: $nfcc-light-blue;
    }
  }
}
