.what-we-do {
  &.nfcc-dark-blue-bg {
    background-color: $nfcc-dark-blue;
  }

  h2 {
    font-size: 1.5rem;
  }

  img {
    display: block;
    margin: 0 auto 1rem;
  }
}
