.call-to-action-button {
  background-color: $primary-blue;
  color: white;
  cursor: pointer;
  max-width: 22rem;

  &:hover {
    background-color: $secondary-blue;
    color: white;
  }
}

.ko-call-to-action {
  border: 1px solid #005AA3;
  color: #005AA3;
  background-color: white;
  padding: 1rem 4rem;
  border-radius: 50px;
  max-width: 22rem;
}
