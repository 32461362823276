.agencies {
  color: black;

  &.agency-header {
    background-color: white;
    border-bottom: 1px solid #D1D1D1;
  }

  .border-left {
    border-left: 1px solid #D1D1D1;
  }

  .logo {
    position: relative;
    top: -0.4rem;
    max-width: 225px;
    @media only screen and (max-width: 640px) {
      max-width: 160px;
    }
  }
}
