.how-it-works {
  .steps {
    text-align: center;
    width: 100%;
    padding-bottom: 4rem;

    h2 {
      line-height: 1.8rem;
    }

    .step-number {
      width: 40px;
      height: 40px;
      font-size: 1.5rem;
      text-align: center;
      color: white;
      border-radius: 50%;
      background-color: $nfcc-green;
      margin: 2rem auto 1rem;
      padding-top: 0.2rem;
    }

    .step-details {
      margin: auto;
    }
  }
}
