.expenses-summary-component {
  .review-application {
    .review-tile-container {
      margin: 0;

      &.application-component-container {
        width: 100%;
      }
    }

    .review-tile {
      min-width: 20rem;

      .edit-value {
        top: -2rem;
      }

      .details {
        max-width: 100%;
      }
    }
  }
}

.income-amounts {
  .right-border {
    border-right: 1px solid #E5E5E5;
  }

  .sub-amount {
    color: $green;
    font-family: 'Rift';
    font-size: 2.25rem;
  }
}
