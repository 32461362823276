$nfcc-dark-blue: #150C39;

.nfcc-footer {
  background-color: $nfcc-dark-blue;

  p {
    color: white;
  }

  a {
    color: white;
  }
}
