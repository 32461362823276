@import '../../components/customer-service-portal/ApplicationSummaryReadOnlyComponents/application-summary-read-only-component.scss';
@import '../../components/customer-service-portal/BorrowerQueueComponents/borrower-queue.scss';
@import '../../components/customer-service-portal/BorrowerSummaryComponents/borrower-summary-components.scss';
@import '../../components/customer-service-portal/common/FilterResults/filter-results';
@import '../../components/customer-service-portal/common/Header/header';
@import '../../components/customer-service-portal/common/OnBoardingHeader/on-boarding-header';
@import '../../components/customer-service-portal/ContactSummaryComponents/contact-summary-components';
@import '../../components/customer-service-portal/ContactSummaryComponents/QuestionsComponents/contact-summary-questions';
@import '../../components/customer-service-portal/EditPreferences/edit-preferences';
@import '../../components/customer-service-portal/IncomeAndExpenseSummaryComponent/income-and-expense-summary-component';
@import '../../components/customer-service-portal/SummaryComponents/summary-components';
@import '../../components/customer-service-portal/SummaryComponents/BorrowerTile/borrower-tile';
@import '../../components/customer-service-portal/SummaryComponents/SummaryFilterComponent/summary-filter-component';
@import '../../components/customer-service-portal/TransferApplicationTile/transfer-application-tile';
@import './DebtSummary/debt-summary';
@import './OfferSummary/offer-summary';
@import './PlanEnrollmentSummary/plan-enrollment-summary.scss';

.customer-service-portal {
  h1 {
    padding: 0.5rem 0 1rem;
    margin: 0;
    line-height: 0;
    letter-spacing: 0.1rem;
  }

  .footer {
    @media only screen and (max-width: 640px) {
      position: relative;
      top: -220px;
    }
  }

  .call-to-action {
    &.orange-bg {
      background-color: $orange;
    }
  }
}
