.about-you {
  .headline {
    font-size: 2.25rem;
    @media only screen and (max-width: 640px) {
      font-size: 1.5rem;
      padding-top: 1rem;
    }
  }

  .without-a-loan {
    font-size: 1.125rem;
  }

  .form-width {
    background-color: $nfcc-dark-blue;
    padding: 40px 30px;
    box-shadow: 0px 3px 10px rgba(0,0,0,.5);
    max-width: 27rem;
    @media only screen and (max-width: 640px) {
      max-width: 100%;
    }

    @media only screen and (max-width: 400px) {
      padding: 40px 8px;
    }

    .free-online-analysis {
      font-size: 1.125rem;
      @media only screen and (max-width: 400px) {
        font-size: 0.8rem;
      }
    }

    .terms {
      color: white;
      padding: 0 2rem 0 0;
    }
  }
}
