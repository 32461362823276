.how-it-works-modal {
  background-color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: visible;

  .step {
    position: relative;
    .call-to-action-button {
      border-radius: 50px;
      background-color: $orange;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      padding: 1rem 0;
      font-weight: bold;
      &:hover {
        background-color: $dark-orange;
      }
    }

    .call-to-action {
      background-color: transparent;
      color: $secondary-blue;
      border-radius: 50px;
      border: 1px solid $secondary-blue;
      cursor: pointer;
      max-width: 22rem;
      font-weight: bold;
      outline: none;
      &:hover {
        background-color: $light-grey;
      }
    }
  }

  h1 {
    font-family: 'Montserrat-black';
    font-style: italic;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $secondary-blue;
    padding-top: 0;
  }

  h3 {
    font-family: 'Montserrat-light';
    text-transform: none;
  }

  p {
    font-family: 'Montserrat-light';
  }

  .slider-dots {
    list-style: none;
  }

  .slider-dots li {
    color: $grey;
    display: inline;
    font-size: 40px;
    margin-right: 5px;
  }

  .slider-dots li.active-dot {
    color: $primary-blue;
  }
}
